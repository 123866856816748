<template>
  <section>
    <div v-if="isLoading">
      <aom-skeleton-loader />
    </div>
    <div v-else>
      <b-card
        img-left
        class="mb-3"
      >
        <b-row>
          <b-col
            v-for="m in match.match_users"
            :key="m.id"
            sm="6"
          >
            <b-media
              no-body
              class="justify-content-center"
            >
              <b-media-aside>
                <b-avatar
                  variant="primary"
                  :text="userInitials(m.user)"
                  size="8rem"
                />
              </b-media-aside>
            </b-media>
            <router-link
              :to="{
                name: 'champion-program-participant',
                params: { id: defaultProgramId, participantId: m.user.id },
              }"
            >
              <h4 class="text-center mt-1">
                {{ m.user.full_name }}
              </h4>
            </router-link>
            <p class="text-center mt-1">
              {{ m.user.email }}
            </p>
            <p class="text-center mt-1 text-capitalize">
              <span
                class="mb-0 mt-50"
              ><small class="text-muted text-capitalize">
                <b-badge
                  variant="light"
                  class="text-muted text-capitalize p-50"
                >
                  {{ roleAlias(m.role.id, defaultProgram) }}
                </b-badge>
              </small></span>
            </p>
            <hr>
          </b-col>
          <b-col
            sm="12"
            class="mt-1 text-center"
          >
            <b-card-sub-title class="mb-2">
              <b-card-text
                class="d-inline text-dark"
              >
                <b-badge :variant="STATUS_COLOR[matchStatus]">
                  {{ matchStatus }}
                </b-badge>
              </b-card-text>
            </b-card-sub-title>

            <b-row class="hstack gap-3">
              <b-col class="p-0">
                <b-card-sub-title class="text-right m-0">
                  {{ $t('Match Start Date') }}: 
                  <b-card-text class="d-inline text-dark">
                    {{ localeDateStringFromIsoDateTime(match.started_at) }}
                  </b-card-text>
                </b-card-sub-title>
              </b-col>
              <div
                class="mx-1"
                style="border: 1px solid #403f3f"
              />
              <b-col class="p-0">
                <b-card-sub-title class="text-left m-0">
                  {{ $t('Match End Date') }}: 
                  <b-card-text
                    v-if="match.ended_at"
                    class="d-inline text-dark"
                  >
                    {{ localeDateStringFromIsoDateTime(match.ended_at) }}
                  </b-card-text>
                </b-card-sub-title>
              </b-col>
            </b-row>

            <b-col
              sm="12"
              class="mb-2 mt-2 text-center"
            >
              <action-button
                variant="primary"
                class="mr-50"
                size="sm"
              >
                <router-link
                  :to="{
                    name: 'champion-program-communications',
                    query: {
                      user_ids: match.match_users.map((u) => u.user_id),
                    },
                  }"
                  class="text-white"
                >
                  <feather-icon
                    icon="MessageSquareIcon"
                    size="18"
                    class="mr-50"
                  />
                  <span>Message</span>
                </router-link>
              </action-button>

              <action-button  
                v-if="match.status_id === matchStatusModel.ACTIVE"
                variant="primary"
                size="sm"
                @click="unpublishMatch(match)"
              >
                <feather-icon
                  icon="DownloadCloudIcon"
                  size="18"
                  class="mr-50"
                />
                Unpublish
              </action-button>

              <action-button  
                v-if="match.status_id === matchStatusModel.LOCKED || match.status_id === matchStatusModel.SCHEDULED"
                variant="primary"
                size="sm"
                @click="publishMatch(match)"
              >
                <feather-icon
                  icon="UploadCloudIcon"
                  size="18"
                  class="mr-50"
                />
                Publish
              </action-button>
            </b-col>
          </b-col>
        </b-row>
      </b-card>

      <b-tabs
        content-class="col-12 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-12"
        nav-class="nav-left"
      >
        <!-- general tab -->
        <b-tab active>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="FilePlusIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Training Reports</span>
          </template>
          <b-card>
            <aom-skeleton-loader v-if="isLoadingTrainingHistory" />
            <template v-else>
              <h3>Training Reports ({{ trainingHistory.length }})</h3>
              <b-table
                striped
                hover
                :items="trainingHistory"
                :fields="trainingFields"
              />
            </template>
          </b-card>
        </b-tab>
        <!--/ general tab -->
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="FileIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Notes</span>
          </template>
          <b-card>
            <match-notes />
          </b-card>
        </b-tab>
      </b-tabs>
      <publish-match-modal 
        ref="publishMatchModal"
        :matches="matchesToPublish"
        @updated="onPublish"
      />
    </div>
  </section>
</template>

<script>
import { BTabs, BTab, BCard, BMedia, BMediaAside,BAvatar, BRow, BCol, BCardText, BCardSubTitle, BBadge, BTable, BButton} from 'bootstrap-vue';
import { matchesService, trainingsService } from "@/services";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { localeDateStringFromIsoDateTime } from "@/libs/utils";
import { matchStatusDisplay, matchStatus as matchStatusModel } from '@models';
import { STATUS_COLOR } from "@/libs/utils";
import { mapGetters } from 'vuex';
import MatchNotes from './MatchNotes.vue';
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import PublishMatchModal from './PublishMatchModal.vue';
import { roleAlias } from "@/@aom-core/utils/utils";
import ActionButton from "@/views/components/common/ActionButton.vue";

export default {
  name: 'MatchDetails',
  components: {
    ActionButton,
    BTabs,
    BTab,
    BCard,
    BMedia,
    BAvatar,
    BMediaAside,
    BRow,
    BCol,
    BCardText,
    BCardSubTitle,
    BTable,
    BBadge,
    BButton,
    AomSkeletonLoader,
    MatchNotes,
    PublishMatchModal,
  },
  data() {
    return {
      isLoading: false,
      match: undefined,
      localeDateStringFromIsoDateTime,
      STATUS_COLOR,
      matchStatusModel,
      isUpdating: false,
      trainingFields: ['training_course_name', 'participant', 'role', 'date_started', 'date_completed', 'status'],
      isLoadingTrainingHistory: false,
      trainingHistory: [],
      matchesToPublish: []
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    matchStatus() {
      return matchStatusDisplay[this.match.status_id];
    }
  },
  created() {
    this.fetchMatch();
  },
  methods: {
    onPublish() {
      this.matchesToPublish = [];
      this.fetchMatch();
    },
    async fetchMatch() {
      const programId = this.$route.params.id;
      const matchId = this.$route.params.matchId;
      try {
        this.isLoading = true;
        const match = await matchesService.getMatch(programId, matchId);
        this.match = {...match.data};
        this.fetchTrainingHistory(match.data.match_users);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchTrainingHistory(users) {
      try {
        this.isLoadingTrainingHistory = true;
        const programId = this.$route.params.id;
        for (const user of users) {
          const result = await trainingsService.getTrainingLaunchHistories(programId, user.user_id, {
            page: 1,
            perPage: 100,
            columnFilters: [],
            sort: [],
          });
          this.trainingHistory = [...this.trainingHistory, ...result.data.items.map(item => ({
            training_course_name: item.program_training_user.training.course_name,
            participant: user.user.full_name,
            role: roleAlias(user.role_id, this.defaultProgram),
            date_started: localeDateStringFromIsoDateTime(item.program_training_user.first_access_date),
            date_completed: localeDateStringFromIsoDateTime(item.program_training_user.completed_date),
            status: item.program_training_user.status.name
          }))];
        }
      } catch (e) {
        this.$toast(makeErrorToast(e));
        this.$log.error(e);
      } finally {
        this.isLoadingTrainingHistory = false;
      }
    },
    userInitials(user) {
      const firstInt = Array.from(user.first_name)[0];
      const lastInt = Array.from(user.last_name)[0];
      return `${firstInt}${lastInt}`;
    },
    async unpublishMatch(match) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to Unpublish this match? This will sever the match and cannot be undone. Matches that are over 30 days into a program will still be counted in your program match capacity. Do you wish to continue?', {
          title: 'Unpublish Match?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Unpublish Match',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            const data = { ...match, status_id: matchStatusModel.CANCELLED};
            try {
              const programId = this.$route.params.id;
              const result = await matchesService.patchUpdateMatch(programId, match.id, data);
              if (result) {
                this.$toast(makeSuccessToast('Match Unpublished successfully.'));
                this.fetchMatch();
              }
            } catch (e) {
              console.log(e);
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },
    async publishMatch(match) {
      this.matchesToPublish = [match];
      this.$nextTick(() => {
        this.$refs.publishMatchModal.open();
      });
    }
  },
  setup() {
    return {
      roleAlias
    };
  }
};
</script>
