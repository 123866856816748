var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.isLoading)?_c('div',[_c('aom-skeleton-loader')],1):_c('div',[_c('b-card',{staticClass:"mb-3",attrs:{"img-left":""}},[_c('b-row',[_vm._l((_vm.match.match_users),function(m){return _c('b-col',{key:m.id,attrs:{"sm":"6"}},[_c('b-media',{staticClass:"justify-content-center",attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-avatar',{attrs:{"variant":"primary","text":_vm.userInitials(m.user),"size":"8rem"}})],1)],1),_c('router-link',{attrs:{"to":{
              name: 'champion-program-participant',
              params: { id: _vm.defaultProgramId, participantId: m.user.id },
            }}},[_c('h4',{staticClass:"text-center mt-1"},[_vm._v(" "+_vm._s(m.user.full_name)+" ")])]),_c('p',{staticClass:"text-center mt-1"},[_vm._v(" "+_vm._s(m.user.email)+" ")]),_c('p',{staticClass:"text-center mt-1 text-capitalize"},[_c('span',{staticClass:"mb-0 mt-50"},[_c('small',{staticClass:"text-muted text-capitalize"},[_c('b-badge',{staticClass:"text-muted text-capitalize p-50",attrs:{"variant":"light"}},[_vm._v(" "+_vm._s(_vm.roleAlias(m.role.id, _vm.defaultProgram))+" ")])],1)])]),_c('hr')],1)}),_c('b-col',{staticClass:"mt-1 text-center",attrs:{"sm":"12"}},[_c('b-card-sub-title',{staticClass:"mb-2"},[_c('b-card-text',{staticClass:"d-inline text-dark"},[_c('b-badge',{attrs:{"variant":_vm.STATUS_COLOR[_vm.matchStatus]}},[_vm._v(" "+_vm._s(_vm.matchStatus)+" ")])],1)],1),_c('b-row',{staticClass:"hstack gap-3"},[_c('b-col',{staticClass:"p-0"},[_c('b-card-sub-title',{staticClass:"text-right m-0"},[_vm._v(" "+_vm._s(_vm.$t('Match Start Date'))+": "),_c('b-card-text',{staticClass:"d-inline text-dark"},[_vm._v(" "+_vm._s(_vm.localeDateStringFromIsoDateTime(_vm.match.started_at))+" ")])],1)],1),_c('div',{staticClass:"mx-1",staticStyle:{"border":"1px solid #403f3f"}}),_c('b-col',{staticClass:"p-0"},[_c('b-card-sub-title',{staticClass:"text-left m-0"},[_vm._v(" "+_vm._s(_vm.$t('Match End Date'))+": "),(_vm.match.ended_at)?_c('b-card-text',{staticClass:"d-inline text-dark"},[_vm._v(" "+_vm._s(_vm.localeDateStringFromIsoDateTime(_vm.match.ended_at))+" ")]):_vm._e()],1)],1)],1),_c('b-col',{staticClass:"mb-2 mt-2 text-center",attrs:{"sm":"12"}},[_c('action-button',{staticClass:"mr-50",attrs:{"variant":"primary","size":"sm"}},[_c('router-link',{staticClass:"text-white",attrs:{"to":{
                  name: 'champion-program-communications',
                  query: {
                    user_ids: _vm.match.match_users.map(function (u) { return u.user_id; }),
                  },
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"MessageSquareIcon","size":"18"}}),_c('span',[_vm._v("Message")])],1)],1),(_vm.match.status_id === _vm.matchStatusModel.ACTIVE)?_c('action-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.unpublishMatch(_vm.match)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DownloadCloudIcon","size":"18"}}),_vm._v(" Unpublish ")],1):_vm._e(),(_vm.match.status_id === _vm.matchStatusModel.LOCKED || _vm.match.status_id === _vm.matchStatusModel.SCHEDULED)?_c('action-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.publishMatch(_vm.match)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UploadCloudIcon","size":"18"}}),_vm._v(" Publish ")],1):_vm._e()],1)],1)],2)],1),_c('b-tabs',{attrs:{"content-class":"col-12 mt-1 mt-md-0","pills":"","nav-wrapper-class":"col-12","nav-class":"nav-left"}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"FilePlusIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Training Reports")])]},proxy:true}])},[_c('b-card',[(_vm.isLoadingTrainingHistory)?_c('aom-skeleton-loader'):[_c('h3',[_vm._v("Training Reports ("+_vm._s(_vm.trainingHistory.length)+")")]),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.trainingHistory,"fields":_vm.trainingFields}})]],2)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"FileIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Notes")])]},proxy:true}])},[_c('b-card',[_c('match-notes')],1)],1)],1),_c('publish-match-modal',{ref:"publishMatchModal",attrs:{"matches":_vm.matchesToPublish},on:{"updated":_vm.onPublish}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }